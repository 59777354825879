.primary-background {
  background-color: #22577e;
  min-height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.font-roboto-mono {
  font-family: 'Roboto Mono', monospace;
}

.font-circular {
  font-family: 'Circular Std';
}

.font-bold {
  font-weight: 700;
}

.amplify-button[data-variation='primary'] {
  background: #3f7194;
  &:hover {
    background: #093658;
  }
}

.amplify-tabs-item[data-state='active'] {
  color: #52b2ad;
}

.amplify-tabs-item[data-state='active'] {
  border-color: #a2f17a;
}

.amplify-button[data-size='small'] {
  color: #52b2ad;
  &:hover {
    color: #093658;
    background: #f4f8ff;
  }
}
